import React from "react";
import PropTypes from "prop-types";
import { prop } from "ramda";
import SignatureFocusContext from "../../hooks/signature-focus-context";
import { useFormikContext } from "formik";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { getNextError, scrollToElement } from "../../utils";
import "./style.scss";

const PatientFormControls = ({ dmePreview, onCancel }) => {
  const {
    focusedEl,
    focusElement,
    pageCount,
    setCurrentPage,
    currentPage,
    formElements,
  } = React.useContext(SignatureFocusContext);
  const { errors, handleSubmit, touched, validateForm, isValid } =
    useFormikContext();
  const fieldError = prop(prop("id", focusedEl), errors);
  const fieldTouched = prop(prop("id", focusedEl), touched);
  React.useEffect(() => {
    validateForm();
  }, [validateForm]);
  const [initialItemFocused, setInitialItemFocused] = React.useState(false);

  React.useEffect(() => {
    const activeElement = formElements[focusedEl.index];
    if (activeElement) {
      const elm = document.getElementById(activeElement.id);
      if (elm && !initialItemFocused) {
        scrollToElement(elm);
        setInitialItemFocused(true);
      }
    }
  }, [focusedEl, formElements, initialItemFocused]);
  return (
    <React.Fragment>
      <div className="patient-form-controls next-prev-controls no-print">
        <div style={{ color: "#DDD", paddingLeft: 5 }}>Pages</div>
        <div
          className={`button default small ${
            currentPage <= 0 ? "disabled" : ""
          }`}
          onClick={() => setCurrentPage((x) => --x)}
        >
          <GrFormPrevious size={20} />
        </div>
        <div className="form-progress">
          {currentPage + 1}/{pageCount}
        </div>
        <div
          onClick={() => setCurrentPage((x) => ++x)}
          className={`button default small ${
            currentPage + 1 >= pageCount ? "disabled" : ""
          }`}
        >
          <GrFormNext size={20} />
        </div>

        {onCancel && (
          <div onClick={onCancel} className={"button default small"}>
            Back
          </div>
        )}
        <div className={`form-requirement ${fieldTouched ? "error" : ""}`}>
          {fieldError}
        </div>
        {!dmePreview && (
          <button
            className="button primary"
            type="submit"
            onClick={() => {
              if (!isValid) {
                const nextError = getNextError(formElements, errors);
                if (nextError && nextError.id) focusElement(nextError.id);
              }
              handleSubmit();
            }}
          >
            Submit
          </button>
        )}
      </div>
    </React.Fragment>
  );
};

PatientFormControls.propTypes = {
  zoom: PropTypes.number,
  setZoom: PropTypes.func.isRequired,
  dmePreview: PropTypes.bool,
};

export default PatientFormControls;
