import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { deleteFormItem, updateFormItemAttributes } from "../../redux/layout";
import Controls from "./controls";
import useHover from "../../hooks/use-hover";
import useResize from "../../hooks/use-resize";
import { Resizable } from "react-resizable";
import Input from "../ui/input";
import Field from "../ui/field";

const TextElement = ({ type, id, pos, size: origSize, attributes }) => {
  const dispatch = useDispatch();
  const [elemRef, isHovered] = useHover();
  const { size, onResize, onResizeStop } = useResize(id, origSize);
  return (
    <div className="layout-item text-element" ref={elemRef}>
      <Controls
        handleDelete={() => {
          dispatch(deleteFormItem({ id }));
        }}
        isRequired={attributes.required}
        showControls={isHovered}
        EditComponent={
          <div
            className="edit-element-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {type === "text" && (
              <Field label="Label">
                <input
                  value={attributes.placeHolder}
                  onChange={({ target }) => {
                    dispatch(
                      updateFormItemAttributes({
                        id,
                        attributes: {
                          ...attributes,
                          placeHolder: target.value,
                        },
                      }),
                    );
                  }}
                  type="text"
                />
              </Field>
            )}
            <Field label="Background">
              <select
                value={attributes.background}
                onChange={({ target }) => {
                  dispatch(
                    updateFormItemAttributes({
                      id,
                      attributes: { ...attributes, background: target.value },
                    }),
                  );
                }}
              >
                <option value="none">Transparent</option>
                <option value="white">White</option>
              </select>
            </Field>
            {/* <Field label="Underline"> */}
            {/*   <Input */}
            {/*     checked={attributes.underline} */}
            {/*     type="checkbox" */}
            {/*     onChange={({ target }) => */}
            {/*       dispatch( */}
            {/*         updateFormItemAttributes({ */}
            {/*           id, */}
            {/*           attributes: { */}
            {/*             ...attributes, */}
            {/*             underline: target.checked, */}
            {/*           }, */}
            {/*         }) */}
            {/*       ) */}
            {/*     } */}
            {/*   /> */}
            {/* </Field> */}
            <Field label="Required">
              <Input
                checked={attributes.required}
                type="checkbox"
                onChange={({ target }) =>
                  dispatch(
                    updateFormItemAttributes({
                      id,
                      attributes: {
                        ...attributes,
                        required: target.checked,
                      },
                    }),
                  )
                }
              />
            </Field>
            <Field label="Assign Field To Coach">
              <Input
                checked={attributes.assigned_to_coach}
                type="checkbox"
                onChange={({ target }) =>
                  dispatch(
                    updateFormItemAttributes({
                      id,
                      attributes: {
                        ...attributes,
                        assigned_to_coach: target.checked,
                      },
                    }),
                  )
                }
              />
            </Field>
          </div>
        }
      >
        <Resizable
          className="absolutely-positioned"
          height={size.absoluteHeight}
          width={size.absoluteWidth}
          onResize={onResize}
          onResizeStop={onResizeStop}
          resizeHandles={["e"]}
          minConstraints={[100, 20]}
          maxConstraints={[900, 200]}
        >
          <div
            style={{
              width: size.absoluteWidth,
              height: size.absoluteHeight,
              margin: `${size.absoluteTop} 0 0 ${size.absoluteLeft}`,
            }}
          >
            <Input
              type={type}
              className={`default handle ${
                attributes.underline ? "underlined" : ""
              }`}
              placeholder={attributes.placeHolder}
              readOnly
              style={{
                width: "100%",
                height: "100%",
                paddingLeft: 2,
                paddingRight: 2,
                background: attributes.background,
                // borderBottom: attributes.underline ? "2px solid #000" : "none",
              }}
            />
          </div>
        </Resizable>
      </Controls>
    </div>
  );
};

TextElement.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
};

TextElement.defaultProps = {
  type: "text",
};

export default TextElement;
